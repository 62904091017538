<div class="mainContainer">

    <h3>{{this.ls.get('CUSTOMER', 'shopping') + ': ' + ordersHistoryViewService.currentCustomer + ' ' + ordersHistoryViewService.currentCustomerNombre}}</h3>
    
    <div class="lineContainer">
        <h4>{{this.ls.get('REFERENCE', 'shopping')}}</h4>
        <h4>{{this.ls.get('AMOUNT', 'shopping')}}</h4>
        <h4>{{this.ls.get('PENDING_AMOUNT', 'app')}}</h4>
        <h4>{{this.ls.get('DELIVERY_DATE', 'app')}}</h4>
        <h4>{{this.ls.get('ORDER_DATE', 'shopping')}}</h4>
    </div>
    
    <div class="lineContainer" *ngFor="let line of ordersHistoryViewService.currentOrderData">
        <p>{{line[0]}}</p>
        <p>{{line[1]}}</p>
        <p>{{line[2]}}</p>
        <p>{{line[3]}}</p>
        <p>{{line[4]}}</p>
    </div>
</div>

<div>
   
    <button id="confirmCustomerButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        {{this.ls.get('CLOSE', 'user-interface')}}
    </button>
</div>