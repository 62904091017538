<mat-sidenav-container [@mediumFade]
    *ngIf="apiService.isLogged">

    <mat-sidenav id="sidenav" #sidenav>
        
        <div class="header">
        
            <tg-button-image class="sidenavClose"
                src="./assets/icons/arrow-back-white.svg"
                [defaultOpacity]=".3"
                [hoverOpacity]=".5"
                [clickOpacity]="1"
                [clickScale]=".9"
                (click)="sidenav.toggle()">
            </tg-button-image>
            
            <img id="sidenavLogo" class="sidenavLogo"
                 src="./assets/icons/centroalum-logo.svg"
                 (click)="sidenav.toggle();
                          viewsService.pushView(homeViewComponent)" />
            
            <h1 (click)="sidenav.toggle();
                         viewsService.pushView(homeViewComponent)">
                Centroalum
            </h1>
            
        </div>
        
        <p id="productsMenuButton"
           (click)="sidenav.toggle(); viewsService.pushView(productsViewComponent)">
                    
            {{ls.get('PRODUCTS', 'shopping')}}
            
        </p>
        
        <p id="catalogsMenuButton"
           (click)="sidenav.toggle(); viewsService.pushView(catalogsViewComponent)">
                    
            {{ls.get('CATALOGS', 'shopping')}}
            
        </p>
        
        <p id="ordersMenuButton"
           *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS)"
           (click)="sidenav.toggle(); viewsService.pushView(ordersViewComponent)">
          
            {{ls.get('ORDERS', 'shopping')}}
          
        </p>
        
        <p id="configuratorMenuButton"
           *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR)"
           (click)="sidenav.toggle(); browserService.goToUrl('https://centroalum.uniapp.no/', true)">
          
            {{ls.get('CONFIGURATOR', 'user-interface')}}
          
        </p>
        
        <p id="reportMenuButton"
           (click)="sidenav.toggle(); browserService.goToUrl('https://transmitancia.unitest.site/', true)">
          
            {{ls.get('REPORT', 'shopping')}}
          
        </p>
        
    </mat-sidenav>
    
    <mat-sidenav-content>
    
        <div class="topBar">

            <h2 class="mainTitle">{{appModel.mainTitle}}</h2>
        
            <tg-button-image id="mainMenuButton" class="menuButton"
                src="./assets/icons/hamburguer-menu.svg"
                [defaultOpacity]=".6"
                [hoverOpacity]=".7"
                [clickOpacity]="1"
                [clickScale]=".9"
                (mousedown)="sidenav.toggle()">
            </tg-button-image>
            
            <img id="topLogoBig" class="topLogoBig" src="./assets/icons/centroalum-logo2.svg" 
                (click)="viewsService.pushView(homeViewComponent)" />
                
            <img class="topLogoSmall" src="./assets/icons/centroalum-logo.svg" />
            
            <div class="bigMenu">
                <hr>
                <div>                    
                    <h2 (click)="viewsService.pushView(productsViewComponent)">
                        {{ls.getAllUpperCase('PRODUCTS', 'shopping')}}
                    </h2>
                    <h2 (click)="viewsService.pushView(catalogsViewComponent)">
                        {{ls.getAllUpperCase('CATALOGS', 'shopping')}}
                    </h2>
                    <h2 *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_ORDERS)"
                        (click)="viewsService.pushView(ordersViewComponent)">
                        {{ls.getAllUpperCase('ORDERS', 'shopping')}}
                    </h2>
                    <h2 *ngIf="apiService.isUserAllowedTo(userModel.APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR)"
                        (click)="browserService.goToUrl('https://centroalum.uniapp.no/', true)">
                        {{ls.getAllUpperCase('CONFIGURATOR', 'user-interface')}}
                    </h2>
                    <h2 (click)="browserService.goToUrl('https://transmitancia.unitest.site/', true)">
                        {{ls.getAllUpperCase('REPORT', 'shopping')}}
                    </h2>
                    <h2 (click)="logout()">
                        {{ls.getAllUpperCase('QUIT', 'user-interface')}}
                    </h2>
                </div>
            </div>
                        
            <tg-button-image id="logoutButton" class="logoutButton"
                src="./assets/icons/logout.svg"
                [defaultOpacity]=".3"
                [hoverOpacity]=".6"
                [clickOpacity]="1"
                [clickScale]=".7"
                (mousedown)="logout()">
            </tg-button-image>
            
        </div>
        
        <tg-views-container
            [viewsService]="viewsService"
            [initialView]="homeViewComponent">
        </tg-views-container>
        
    </mat-sidenav-content>
    
</mat-sidenav-container>

<app-login *ngIf="!apiService.isLogged">
</app-login>