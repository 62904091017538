<h3>{{ls.get('SEND_ORDER', 'shopping') + '?'}}</h3>

<p>{{ls.get('CONFIRM_SENDING_CURRENT_ORDER', 'app') + ' ' + ordersNewOrderViewService.order.total + '€'}}</p>

<h4>{{ls.get('ORDER_DATE', 'shopping')}}: {{ ordersNewOrderViewService.order.orderDate }}</h4>
<h4>
    {{ls.get('SHIPPING_DATE', 'shopping')}}: {{ ordersNewOrderViewService.order.shipmentDate }}
    <span id="selectShippingDateLink" (click)="selectShippingDate()">{{ls.get('MODIFY', 'user-interface')}}</span>
</h4>

<mat-form-field *ngIf="ordersNewOrderViewService.order.customer.direccionesEnvio.length > 1">
            
    <mat-label>{{this.ls.get('SHIPPING_ADDRESS', 'shopping')}}</mat-label>
    
    <mat-select id="selectShippingAddressInput"
        [(ngModel)]="ordersNewOrderViewService.order.shippingAddress">
    
        <mat-option *ngFor="let value of ordersNewOrderViewService.order.customer.direccionesEnvio; let i = index" [value]="value">
          {{ ordersNewOrderViewService.order.customer.direccionesEnvioDetalle[i] }}
        </mat-option>
                    
    </mat-select>
  
</mat-form-field>

<mat-form-field>
    <mat-label>{{ls.get('ORDER_REFERENCE', 'app')}}</mat-label>
    <input matInput autoSelectTextOnFocus
        [(ngModel)]="ordersNewOrderViewService.order.referenciaPedido">
</mat-form-field>

<mat-form-field>
    
    <mat-label>{{ls.get('COMMENTS', 'user-interface')}}</mat-label>
    
    <input matInput autoSelectTextOnFocus
            maxlength="60"
            [(ngModel)]="ordersNewOrderViewService.order.comment">
    
</mat-form-field>

<mat-checkbox id="requestOfferNoOrderCheckBox" name="requestOfferNoOrderCheckBox"
    [(ngModel)]="ordersNewOrderViewService.order.isOrderAnOffer">

    {{ls.getAllUpperCase('REQUEST_OFFER_INSTEAD_OF_ORDER', 'app')}}
    
</mat-checkbox>


<div class="submitButtonsContainer">
    
    <button id="cancelLineButton" mat-button
        (click)="closeDialog(0)">
        
        {{ls.get('CANCEL', 'user-interface')}}
        
    </button>
    
    <button id="addLineButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        <div>
            <img src="./assets/icons/local-shipping.svg" />    
            <p>{{ls.getAllUpperCase('SEND_ORDER', 'shopping')}}</p>
        </div>
            
    </button>

</div>