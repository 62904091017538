import { Component, HostBinding } from '@angular/core';
import { FadeAnimationClass, BusyStateBaseComponent } from 'turbogui-angular';


/**
 * Base busy state component extended to adapt visual appearance
 */
@Component({
  selector: 'app-busy-state',
  templateUrl: './busy-state.component.html',
  animations: [FadeAnimationClass.getTrigger('busyStateBaseFade', '1s ease', '400ms ease')],
  styleUrls: ['./busy-state.component.scss']
})


export class BusyStateComponent extends BusyStateBaseComponent {


    /**
     * This is used to attach the fade animation directly to this component so it fades in and out when created and removed from the app
     */
    @HostBinding('@busyStateBaseFade') busyStateBaseFade = true;
}
