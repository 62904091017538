<img class="logo" src="./assets/icons/centroalum-logo2.svg" />
    
<div class="loginContainer">
    
    <mat-form-field>
        <mat-label>{{ls.get('USER', 'users')}}</mat-label>
        <input id="userLoginInput" matInput [(ngModel)]="apiService.userName">
    </mat-form-field>
    
    <mat-form-field>
        <mat-label>{{ls.get('PASSWORD', 'users')}}</mat-label>
        <input id="passwordLoginInput" matInput type="password" [(ngModel)]="apiService.password">
    </mat-form-field>

    <button id="loginButton" mat-raised-button color="primary"
        [disabled]="!apiService.isUserAndPswDefined"
        (click)="login()">
                
        {{ls.get('LOGIN', 'users')}}
        
    </button>

</div>

<p id="newUserButton" (click)="createNewUser()">{{ls.get('NEW_USER', 'users')}}</p>
