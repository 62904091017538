import { Injectable } from '@angular/core';
import { TurboApiCallerService, ViewService } from 'turbogui-angular';
import { BrowserManager } from 'turbocommons-ts';


/**
 * The service for the catalogs view
 */
@Injectable()
export class CatalogsViewService extends ViewService {


    /**
     * Tells if the view is currently showing a catalog or a list of folders
     */
    isShowingCatalog = false;


    /**
     * The id for the item that is currently being defined as the parent
     */
    currentItemId = '';


    /**
     * Contains the page that is currently being viewed for the current catalog
     */
    currentCatalogPage = 0;


    /**
     * Contains the total amount of pages for the current catalog
     */
    currentCatalogTotalPages = 0;


    /**
     * Contains the currenlty displayed catalog image as a base 64 string
     */
    currentCatalogImage = '';


    /**
     * List of catalog items that have been loaded for the specified parent folder
     */
    itemsList: any[] = [];


    /**
     * Browser manager instance used by this class
     */
    private readonly browserManager = new BrowserManager();


    constructor(private readonly apiService: TurboApiCallerService) {
    
        super();
    }


    /**
     * Load the list of catalog items for the specified parent or the specified catalog image
     */
    load(id = '', isDirectory = true) {

        this.itemsList = [];
        this.currentItemId = id;
        this.isShowingCatalog = false;
        this.currentCatalogImage = '';

        if (isDirectory) {

            this.currentCatalogPage = 0;
            
            this.apiService.call('catalogs/catalogs-list', {parent: id}).then(response => {
                
                this.itemsList = response;
            });

        } else {

            // We perform a call to the catalog page and catalog pages count services with a single http request
            let services = [
                {
                    uri: 'api/catalogs/catalog-pages-count',
                    postParameters: {token:this.apiService.token, id: id}
                },
                {
                    uri: 'api/catalogs/catalog-page',
                    postParameters: {token:this.apiService.token},
                    urlParameters: [String(id), String(this.currentCatalogPage)]
                }
            ];
            
            this.apiService.callChain('turbosite/chain/chain-services', services).then(response => {
                
                this.currentCatalogTotalPages = response[0];
                this.currentCatalogImage = '"data:image/jpg;base64, ' + (response[1] as string) + '"';
                this.isShowingCatalog = true;
            });   
        }
    }


    /**
     * Load the next catalog page
     */
    loadNextCatalogPage() {

        this.currentCatalogPage ++;

        this.load(this.currentItemId, false);
    }


    /**
     * Load the previous catalog page
     */
    loadPrevCatalogPage() {

        if (this.currentCatalogPage <= 0) {

            return;
        }

        this.currentCatalogPage --;

        this.load(this.currentItemId, false);
    }


    /**
     * Download the specified catalog
     */
    catalogDownload(id: string) {

        this.browserManager.goToUrl(this.apiService.baseUrl + '/catalogs/catalog-download/' + id, true,
            {token: this.apiService.token});
    }
}
