<h2>{{ls.get('ORDERS_LIST', 'shopping')}}</h2>

<div class="notAvailableContainer" *ngIf="ordersListViewService.itemsList.length === 0">
    <h3>{{ls.get('NOT_AVAILABLE', 'shopping')}}</h3>
</div>

<div class="ordersContainer" *ngIf="ordersListViewService.itemsList.length > 0">
    
    <div>
        <h4 class="firstCell">#</h4>
        <h4>{{ls.get('DATE', 'calendar')}}</h4>
        <h4>{{ls.get('CUSTOMER_CODE', 'shopping')}}</h4>
        <h4>{{ls.get('NAME', 'personal-data')}}</h4>
        <h4>{{ls.get('ORDER_REFERENCE', 'app')}}</h4>
    </div>
    
    
    <tg-button-container *ngFor="let item of ordersListViewService.itemsList; let i = index"
        id="ordersListItem_{{i}}"
        [@contentFade]
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".8"
        [hoverOpacity]="1"
        [clickScale]="1.04"
        (click)="onOrderClick(item)">
        
        <p *ngFor="let cell of item; let i = index">{{cell}}</p>
                
    </tg-button-container>

</div>