import { Injectable } from '@angular/core';


/**
 * Model containing all the application configurable parameters
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigurationModel {


    /**
     * An url that points to the backend server entry point root
     * Notice that this is replaced with the right value on build and release via the turbobuilder.json wildcards feature
     */
    serverRootUrl = 'https://api.centroalum.com/_dev/api';

    
    /**
     * The url for the official centroalum website
     */
    centroalumOfficialWebSiteUrl = 'https://centroalum.es';

    

    /**
     * List with the application locales
     */
    locales = ['es_ES'];


    /**
     * The paths to the application resourcebundles
     * Notice that we are using build version wildcard to prevent browser from caching old locales on new app versions.
     * This wildcard is replaced by turbobuilder on build and release process
     */
    bundles = [{
                   label: 'project-locales',
                   path: 'assets/locales/$bundle/$bundle_$locale.properties?2.1.1',
                   bundles: ['app']
               },
               {
                   label: 'turbolocales',
                   path: 'assets/locales/turbolocales/$bundle/$bundle_$locale.properties?2.1.1',
                   bundles: ['users', 'user-interface', 'shopping', 'personal-data', 'calendar']
               }];
}
