import { Component, ElementRef, Inject } from '@angular/core';
import { LocalizationService, DialogBaseComponent, TurboApiCallerService } from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { CommonModule } from '@angular/common';


/**
 * Login component that is always shown when no user is loged
 */
@Component({
    selector: 'app-customer-details',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    providers: [],
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss']
})


export class CustomerDetailsComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "CustomerDetailsComponent";
    

    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalizationService,
                public apiService: TurboApiCallerService,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    
        super(elementRef, dialogRef);
        
        this.apiService.call('customers/customer-data',
            {'codigo': this.ordersNewOrderViewService.order.customer.codigo})
            .then(response => {
            
            this.ordersNewOrderViewService.order.customer = response;  
        });
    }
}
