import { Component, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalizationService, DialogService, DialogTwoOptionComponent, ButtonContainerComponent, FadeAnimationClass } from 'turbogui-angular';
import { AppModel } from 'src/main/model/models/app.model';
import { Customer } from 'src/main/model/dtos/Customer';
import { OrderNewLineComponent } from './order-new-line.component';
import { ObjectUtils } from 'turbocommons-ts';
import { UserModel } from 'src/main/model/models/user.model';
import { CustomerDetailsComponent } from './customer-details.component';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { LoadOrderFileComponent } from './load-order-file.component';
import { CustomerService } from '../../../controller/customer.service';
import { CommonModule } from '@angular/common';


/**
 * Orders view
 */
@Component({
    selector: 'app-orders-new-order-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, CustomerDetailsComponent],
    providers: [OrdersNewOrderViewService],
    templateUrl: './orders-new-order-view.component.html',
    animations: [FadeAnimationClass.getTrigger('fadeIn')],
    styleUrls: ['./orders-new-order-view.component.scss']
})


export class OrdersNewViewComponent extends View implements AfterViewInit, OnDestroy {


    constructor(public ls: LocalizationService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public customerService: CustomerService,
                public appModel: AppModel,
                public userModel: UserModel,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersNewOrderViewService);

        this.appModel.mainTitle = this.ls.get('NEW_ORDER', 'shopping');
        this.dialogService.addCloseApplicationWarning();
    }


    ngAfterViewInit() {

        // If the current user is a customer one, we will directly set its data here
        // Otherwise, we will directly call for the select customer popup
        if(this.userModel.isCustomer){

            this.ordersNewOrderViewService.order.customer.codigo = this.userModel.customer.codigo;
            this.ordersNewOrderViewService.order.customer.nombre = this.userModel.customer.nombre;
            this.ordersNewOrderViewService.order.customer.direccion = this.userModel.customer.direccion;
            this.ordersNewOrderViewService.order.customer.direccionesEnvio = this.userModel.customer.direccionesEnvio;
            this.ordersNewOrderViewService.order.customer.direccionesEnvioDetalle = this.userModel.customer.direccionesEnvioDetalle;
            this.ordersNewOrderViewService.order.customer.tieneControl = this.userModel.customer.tieneControl;
            
            this.createNewOrderLine();

        }else{

            this.selectCustomer();
        }
    }

    /**
     * Search for a customer to the new order that will be created
     */
    selectCustomer() {
        
        if(this.ordersNewOrderViewService.order.customer.codigo === ''){

            this.customerService.selectCustomer((codigo, nombre) => {
                            
                if(codigo != ''){
                    
                    this.ordersNewOrderViewService.order.customer.codigo = codigo;
                    this.ordersNewOrderViewService.order.customer.nombre = nombre;
                                
                    this.showCustomerDetails();
                }
            });

        }else{
            
            this.showCustomerDetails();
        }
    }


    /**
     * Show the information for the currently selected user
     */
    showCustomerDetails(){

        this.dialogService.addDialog(CustomerDetailsComponent, { width: '800px', viewContainerRef: this.viewContainerRef }, selection =>{

            if(selection.index === 0){

                this.ordersNewOrderViewService.order.customer = new Customer();

                this.selectCustomer();

            }else if(this.ordersNewOrderViewService.order.orderLines.length === 0){

                this.createNewOrderLine();
            }
        });
    }


    createNewOrderLine(){

        this.ordersNewOrderViewService.selectedOrderLine = -1;

        this.dialogService.addDialog(OrderNewLineComponent, {width: '900px', viewContainerRef: this.viewContainerRef});
    }


    editOrderLine(i: number){

        this.ordersNewOrderViewService.orderLineBeingEdited = ObjectUtils.clone(this.ordersNewOrderViewService.order.orderLines[i]);

        this.dialogService.addDialog(OrderNewLineComponent, {width: '900px', viewContainerRef: this.viewContainerRef});
    }


    deleteOrderLine(i: number, description:string){

        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.get('DELETE_ORDER_LINE', 'app') + '?', description],
                options: [this.ls.get('DELETE', 'user-interface'), this.ls.get('CANCEL', 'user-interface')]
            },
            selection =>{

                if(selection.index === 0){

                    this.ordersNewOrderViewService.order.orderLines.splice(i, 1);
                    this.ordersNewOrderViewService.selectedOrderLine = -1;
                    this.ordersNewOrderViewService.order.total = this.ordersNewOrderViewService.calculateOrderTotal(this.ordersNewOrderViewService.order.orderLines);
                }
            });
    }
    
    
    loadLocalFile(){

        this.dialogService.addDialog(LoadOrderFileComponent, {
            modal: false,
            width: '900px',
            viewContainerRef: this.viewContainerRef
            });
    }
   
    
    /**
     * Remove subscriptions on component removal
     */
    ngOnDestroy() {

        this.appModel.mainTitle = '';
        this.dialogService.removeCloseApplicationWarning();
    }
}
