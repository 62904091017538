<h3>{{this.ls.get('CUSTOMER_DATA', 'shopping')}}</h3>

<h5>{{this.ls.get('CODE', 'personal-data')}}:</h5>
<p>{{ordersNewOrderViewService.order.customer.codigo === '' ? '-' : ordersNewOrderViewService.order.customer.codigo}}</p>

<h5>{{this.ls.get('NAME', 'personal-data')}}:</h5>
<p>{{ordersNewOrderViewService.order.customer.nombre === '' ? '-' : ordersNewOrderViewService.order.customer.nombre}}</p>

<h5>{{this.ls.get('PHONE', 'personal-data')}}:</h5>
<p>{{ordersNewOrderViewService.order.customer.telefono === '' ? '-' : ordersNewOrderViewService.order.customer.telefono}}</p>

<h5>{{this.ls.get('EMAIL', 'personal-data')}}:</h5>
<p>{{ordersNewOrderViewService.order.customer.mail === '' ? '-' : ordersNewOrderViewService.order.customer.mail}}</p>

<h5>{{this.ls.get('ADDRESS', 'personal-data')}}:</h5>
<p>{{ordersNewOrderViewService.order.customer.direccion === '' ? '-' : ordersNewOrderViewService.order.customer.direccion}}</p>

<h5>{{this.ls.get('COMMENTS', 'user-interface')}}:</h5>
<p>-</p>

<button id="confirmCustomerButton" mat-raised-button color="primary"
    (click)="closeDialog(1)">
    
    {{this.ls.get('CONFIRM_CUSTOMER', 'shopping')}}
    
</button>

<button mat-button color="primary"
    (click)="closeDialog(0)">
    
    {{this.ls.get('SELECT_ANOTHER_CUSTOMER', 'shopping')}}
    
</button>