<div class="foldersContainer" *ngIf="catalogsViewService.itemsList.length > 0">

    <tg-button-container *ngFor="let item of catalogsViewService.itemsList; let i = index"
        id="catalogItemButton_{{i}}"
        [@contentFade]
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".9"
        [clickScale]="1.04"
        (click)="onCatalogItemClick(item)">
        
        <img [class.catalogIcon]="!item.isDirectory"
             src="./assets/icons/{{item.isDirectory ? 'folder.svg' : 'pdf.svg'}}" />
        
        <p>{{item.name}}</p>
                
    </tg-button-container>

</div>

<div class="catalogViewerContainer" [@contentFade]
     *ngIf="catalogsViewService.isShowingCatalog"
     [ngStyle]="{'background-image':'url(' + catalogsViewService.currentCatalogImage + ')'}"
     (click)="catalogsViewService.loadNextCatalogPage()">
 
</div>

<div class="catalogViewerBottomBar" [@contentFade]
    *ngIf="catalogsViewService.isShowingCatalog">

    <tg-button-image
        id="catalogPrevButton"
        [defaultOpacity]=".5"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]=".9"
        src="./assets/icons/arrow-back-black.svg"
        (click)="catalogsViewService.loadPrevCatalogPage()">
    </tg-button-image>
    
    <tg-button-image
        id="catalogDownloadButton"
        [defaultOpacity]=".5"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]=".9"
        src="./assets/icons/download.svg"
        (click)="catalogsViewService.catalogDownload(catalogsViewService.currentItemId)">
    </tg-button-image>
    
    <tg-button-image
        id="catalogNextButton"
        [defaultOpacity]=".5"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]=".9"
        src="./assets/icons/arrow-forward.svg"
        (click)="catalogsViewService.loadNextCatalogPage()">
    </tg-button-image>
    
</div>