import { Component, ElementRef, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { LocalizationService, DialogBaseComponent, ButtonImageComponent, ValidatorsPlus,
         TurboGuiAngularModule, DialogService, DialogSingleInputComponent, DialogSingleOptionComponent,
         TurboApiCallerService,  BrowserService } from 'turbogui-angular';
import { ConfigurationModel } from 'src/main/model/models/configuration.model';


/**
 * Component that allows us to create a new user
 */
@Component({
    selector: 'app-new-user',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatButtonModule, 
        MatInputModule, MatSelectModule, ButtonImageComponent, TurboGuiAngularModule],
    providers: [],
    templateUrl: './new-user.component.html',
    animations: [],
    styleUrls: ['./new-user.component.scss']
})


export class NewUserComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "NewUserComponent";

    
    /** List of values available at the occupation dropdown */
    occupationValues = [
        { value: 'Arquitectura' },
        { value: 'Diseño interiores' },
        { value: 'Construcción' },
        { value: 'Reformista' },
        { value: 'Promotor' },
        { value: 'Otros' },
    ];
    
    
    /** Stores the user instance to be saved */
    userObject = {
        domain: '',
        roles: ['prescriptor'],
        userName: '',
        description: '',
        data: ''
    };
    
    
    /** Stores the user extra custom fields */
    userCustomFields = {
        name: '',
        surnames: '',
        phone: '',
        company: '',
        occupation: '',
        address: '',
        city: '',
        district: '',
        postalCode: ''
    };
    
    
    /** Group to define the form validations */
    form: FormGroup;
    
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                public elementRef: ElementRef,
                public dialogRef: MatDialogRef<DialogBaseComponent>,                
                public ls: LocalizationService,
                public apiService: TurboApiCallerService,
                private browserService: BrowserService,
                private readonly configurationModel: ConfigurationModel,
                public dialogService: DialogService) {
                    
        super(elementRef, dialogRef);
        
        // Define the validations for the form fields
        this.form = this.fb.group({
            name: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.minLength(3)]],
            surnames: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.minLength(5)]],
            email: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.email]],
            phone: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.pattern('^[0-9]*$')]],
            company: ['', ValidatorsPlus.nonEmpty],
            occupation: ['', ValidatorsPlus.nonEmpty],
            address: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.minLength(5)]],
            city: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.minLength(3)]],
            district: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.minLength(3)]],
            postalCode: ['', [ValidatorsPlus.nonEmpty, ValidatorsPlus.pattern('^[0-9]*$')]]
        });
    }
    
    
    /**
     * Occupation is selected via a dropdown, but if the user chooses "others" an input is shown to let him type whatever occupation he has
     */
    onOccupationChangeEvent(value:string){
        
        if(value === 'Otros'){
            
            this.dialogService.addDialog(
                DialogSingleInputComponent,
                {
                    width:'400px',
                    modal: false,
                    texts: [this.ls.get('INDICATE_OCCUPATION', 'personal-data'), this.ls.get('OCCUPATION', 'personal-data')],
                    options: [this.ls.get('SAVE', 'user-interface')]
                }, 
                (selection) =>{
                    
                    if(selection.index === 0){
                                        
                        this.occupationValues.splice(this.occupationValues.length - 1, 0, { value: selection.value });
                        this.userCustomFields.occupation = selection.value;   
                    
                    }else{

                        this.userCustomFields.occupation = '';
                    }                  
                });
        }
    }
    
    
    /** Launch the user creation request */
    createUser(){
         
        this.apiService.call('users/user-create',
            {userObject: this.userObject, userCustomFieldsObject: this.userCustomFields})
            .then(response => {
            
            if(response === true){
            
                this.dialogService.addDialog(DialogSingleOptionComponent, {
                    width:'500px',
                    texts: [this.ls.get('NEW_USER_CREATED_EMAIL_SENT', 'app')],
                    options: [this.ls.get('CLOSE', 'user-interface')]
                }, () => {
                    
                    this.browserService.goToUrl(this.configurationModel.centroalumOfficialWebSiteUrl);
                });
            }
            
            this.closeDialog(0); 
        });
    }
}