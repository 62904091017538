import { Component, OnDestroy, AfterViewInit, ViewContainerRef } from '@angular/core';
import { ViewsService, View, LocalizationService, DialogService } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { AppModel } from 'src/main/model/models/app.model';
import { OrdersWebListViewService } from './orders-web-list-view.service';
import { CommonModule } from '@angular/common';


/**
 * Orders list view
 */
@Component({
      selector: 'app-orders-web-list-view',
      standalone: true,
    imports: [CommonModule],
    providers: [OrdersWebListViewService],
      templateUrl: './orders-web-list-view.component.html',
      animations: [ContentFadeAnimationClass.getTrigger()],
      styleUrls: ['./orders-web-list-view.component.scss']
})


export class OrdersWebListViewComponent extends View implements AfterViewInit, OnDestroy {


    constructor(public ls: LocalizationService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public appModel: AppModel,
                public ordersListViewService: OrdersWebListViewService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersListViewService);

        this.appModel.mainTitle = this.ls.get('ORDERS_LIST', 'shopping');
    }


    ngAfterViewInit() {

        this.ordersListViewService.loadList();
    }
    
    
    /**
     * Obtain the data from a single order
     */
    onOrderClick(item: string[]) {

        this.ordersListViewService.loadOrderData(item[0]);
    }
    
    
    ngOnDestroy() {

        this.appModel.mainTitle = '';
    }
}
