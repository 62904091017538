<div class="mainContainer">

    <h3>{{ordersListViewService.currentOrderData.customer.nombre}}</h3>
    
    <p>{{this.ls.get('CUSTOMER', 'shopping') + ': ' + ordersListViewService.currentOrderData.customer.direccion}}</p>
    
    <div class="lineContainer">
        <h4>{{this.ls.get('AMOUNT', 'shopping')}}</h4>
        <h4>{{this.ls.get('UNIT_PRICE', 'shopping')}}</h4>
        <h4 class="hideOnMobile">{{this.ls.get('REFERENCE', 'shopping')}}</h4>
        <h4>{{this.ls.get('DESCRIPTION', 'shopping')}}</h4>
        <h4 class="hideOnMobile">{{this.ls.get('FINISH', 'shopping')}}</h4>
        <h4 class="hideOnMobile">{{this.ls.get('MEASURE_UNIT', 'app')}}</h4>
        <h4>{{this.ls.get('TOTAL', 'shopping')}}</h4>
    </div>
    
    <div *ngFor="let line of ordersListViewService.currentOrderData.orderLines; let i = index">
        <div class="lineContainer" >
            <p>{{line.units}}</p>
            <p>{{ordersNewOrderViewService.calculateLineUnitPrice(line)}}€</p>
            <p class="hideOnMobile">{{line.product.codigo}}</p>
            <p>{{line.product.descripcion}}</p>
            <p class="hideOnMobile">{{line.variant.variante}}</p>
            <p class="hideOnMobile">{{line.unidadesMedida.code}}</p>
            <p>{{ordersNewOrderViewService.calculateLineTotalPrice(line).toFixed(2)}}€</p>
        </div>
        <ng-container *ngFor="let accessory of line.accesories; let i = index">
            <div class="lineContainer" *ngIf="accessory.units > 0">
                <p>{{accessory.units}}</p>
                <p>{{ordersNewOrderViewService.calculateAccessoryUnitPrice(accessory, line)}}€</p>
                <p class="hideOnMobile">{{accessory.accesorio}}</p>
                <p>{{accessory.descripcion}}</p>
                <p class="hideOnMobile">-</p>
                <p class="hideOnMobile">{{accessory.measureUnits.code}}</p>
                <p>{{ordersNewOrderViewService.calculateAccessoryTotalPrice(accessory, line).toFixed(2)}}€</p>
            </div>
        </ng-container>  
    </div>
</div>

<div>
    
    <h5>{{this.ls.get('TOTAL', 'shopping')}}: {{ordersNewOrderViewService.calculateOrderTotal(ordersListViewService.currentOrderData.orderLines)}}€</h5>

    <button id="confirmCustomerButton" mat-raised-button color="primary"
        (click)="closeDialog(1)">
        
        {{this.ls.get('CLOSE', 'user-interface')}}
    </button>
</div>