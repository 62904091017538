import { Injectable } from '@angular/core';
import { ViewsService, TurboApiCallerService, LocalizationService, DialogService, ViewService } from 'turbogui-angular';
import { OrderHistoryDetailsComponent } from './order-history-details.component';


/**
 * The service for the expansion view
 */
@Injectable()
export class ExpansionRoutesViewService extends ViewService{


    /**
     * Flag that tells if we are showing the details of a route
     */    
    isShowingRouteDetails = false;
    
    
    /**
     * Flag that tells if we are modifying a route
     */    
    isEditingRoute = false;
    
    
    /**
     * List of routes that are available
     */
    routesList: any[] = [];
    
    
    /**
     * Data for the route that is currently being edited
     */
    routeBeingCurrentlyEdited = {
        name: '',
        customers: ''
    };
    
    
    /**
     * Data for the currently selected route
     */
    routeDetails = [];


    /**
     * Date to filter the list of route details by estimate date
     */
    routeDetailsDateFilter = '';
    
    
    /**
     * Data for the order that is being currently shown
     */
    currentOrderData: any = null;
    
    
    /**
     * The customer code for the one we will be listing the order history
     */
    currentCustomer = '';
        

    constructor(public viewsService: ViewsService,
                public ls:LocalizationService,
                public dialogService: DialogService,
                public apiService: TurboApiCallerService) {
                    
        super();
    }


    clearRouteCurrentlyBeingEdited(){

        this.routeBeingCurrentlyEdited = {
            name: '',
            customers: ''
        };
    }
    
    
    loadRoutes(){
        
        this.routesList = [];
        this.routeDetails = [];
                
        this.apiService.call('expansion-routes/routes-list')
            .then(response => {
            
            this.routesList = response;
        });
    }
    
    
    /**
     * Load the list of order items for the current user
     */
    loadOrderData(orderNumber:string, customerCode:string) {

        this.currentOrderData = null;
        this.currentCustomer = customerCode;
        
        this.apiService.call('orders/get-navision-order-details',
            {orderNumber: orderNumber, "codigo-cliente": this.currentCustomer})
            .then(response => {
                    
            this.currentOrderData = response;
                        
            this.dialogService.addDialog(OrderHistoryDetailsComponent, {
                width: '1500px',
                modal: false,
                viewContainerRef: this.viewContainerRef});
        });
    }
}
