<div class="mainElementsContainer">

    <tg-button-container id="selectCustomerButton"
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.07"
        [disabledOpacity]=".7"
        [enabled]="!userModel.isCustomer"
        [class.disabledCustomerButton]="userModel.isCustomer"
        (click)="selectCustomer()">
        
        <img src="{{ordersNewOrderViewService.order.customer.codigo === '' ? './assets/icons/question.svg' : './assets/icons/user-circle.svg'}}" />
        
        <div>

            <h2 [class.smallCustomerTitle]="ordersNewOrderViewService.order.customer.codigo !== ''">{{ls.get('CUSTOMER', 'shopping')}}</h2>
            
            <h2 *ngIf="ordersNewOrderViewService.order.customer.codigo !== ''">{{ordersNewOrderViewService.order.customer.nombre}}</h2>
            
        </div>
    </tg-button-container>
    
</div>

<div class="linesContainer" [@fadeIn]
    *ngIf="ordersNewOrderViewService.order.customer.codigo !== ''">
    
    <h3 class="linesTitle">{{ls.get('ORDER_LINES', 'shopping')}}</h3>

    <div class="linesColumnsHeader" *ngIf="ordersNewOrderViewService.order.orderLines.length > 0">
        <h5>{{ls.get('AMOUNT', 'shopping')}}</h5>
        <h5>{{ls.get('UNIT_PRICE', 'shopping')}}</h5>
        <h5>{{ls.get('REFERENCE', 'shopping')}}</h5>
        <h5 id="linesColumnsHeaderDescription">{{ls.get('DESCRIPTION', 'shopping')}}</h5>
        <h5>{{ls.get('FINISH', 'shopping')}}</h5>
        <h5>{{ls.get('MEASURE_UNIT', 'app')}}</h5>
        <h5>{{ls.get('TOTAL', 'shopping')}}</h5>
    </div>

    <div class="linesSubContainer" *ngIf="ordersNewOrderViewService.order.orderLines.length > 0">
    
        <div class="lineContainer"
            *ngFor="let line of ordersNewOrderViewService.order.orderLines; let i = index">
        
            <div class="unitsContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.units}}</h6>
            </div>
            
            <div class="unitPrice" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{ordersNewOrderViewService.calculateLineUnitPrice(line)}}€</h6>
            </div>
            
            <div class="productCodeContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.product.codigo}}</h6>
            </div>
            
            <div class="productDescriptionContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.product.descripcion}}</h6>
            </div>
            
            <div class="acabadoContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.variant.variante}}</h6>
            </div>
            
            <div  class="unidadesMedidaContainer" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{line.unidadesMedida.code}}</h6>
            </div>
            
            <div class="linePriceTotal" (click)="ordersNewOrderViewService.selectedOrderLine = i; editOrderLine(ordersNewOrderViewService.selectedOrderLine)">
                <h6>{{ordersNewOrderViewService.calculateLineTotalPrice(line).toFixed(2)}}€</h6>
            </div>
            
            <tg-button-container id="deleteOrderLine"
                [defaultOpacity]=".7"
                [hoverOpacity]=".8"
                [clickOpacity]="1"
                [clickScale]="1.07"
                (click)="deleteOrderLine(i, line.units + ' ' + line.unidadesMedida.code + ' - ' + line.product.descripcion)">
                
                <img src="./assets/icons/delete.svg" />
        
            </tg-button-container>  
            
        </div>

    </div>
    
    <div class="newOrderLineContainer">
    
        <tg-button-container id="addNewOrderLine"
            [defaultOpacity]=".7"
            [hoverOpacity]=".8"
            [clickOpacity]="1"
            [clickScale]="1.07"
            (click)="createNewOrderLine()">
            
            <img src="./assets/icons/add.svg" />
    
        </tg-button-container>
        
        <tg-button-container id="loadLocalFile"
            [defaultOpacity]=".7"
            [hoverOpacity]=".8"
            [clickOpacity]="1"
            [clickScale]="1.07"
            (click)="loadLocalFile()">
            
            <img src="./assets/icons/upload.svg" />
    
        </tg-button-container>
        
    </div>
            
</div>
    
<tg-button-container id="submitOrderButton"
    [defaultOpacity]="1"
    [hoverOpacity]="1"
    [clickOpacity]="1"
    [clickScale]="1.07"
    *ngIf="ordersNewOrderViewService.order.orderLines.length > 0"
    (click)="ordersNewOrderViewService.sendCurrentOrder()">
    
    <img src="./assets/icons/done.svg" />
    
    <h2>{{ls.get('SEND_ORDER', 'shopping') + ' (' + ls.get('TOTAL', 'shopping') + ': ' + ordersNewOrderViewService.order.total + '€)'}}</h2>
    
</tg-button-container>  