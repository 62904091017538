import { Component, ElementRef } from '@angular/core';
import { LocalizationService, DialogBaseComponent, BrowserService, ViewsService, DialogService } from 'turbogui-angular';
import { MatDialogRef } from '@angular/material/dialog';
import { StringUtils } from 'turbocommons-ts';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';


/**
 * Component that lets us add a new line to the current order
 */
@Component({
      selector: 'load-order-file',
      standalone: true,
    imports: [],
    providers: [],
      templateUrl: './load-order-file.component.html',
      styleUrls: ['./load-order-file.component.scss']
})


export class LoadOrderFileComponent extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "LoadOrderFileComponent";
    

    stringUtils = StringUtils;


    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalizationService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public browserService: BrowserService,
                public ordersNewOrderViewService: OrdersNewOrderViewService) {
       
       super(elementRef, dialogRef);
    }
      
    
    onFileSelected(event: any) {
        
        this.browserService.browseLocalFiles(event, "BASE64", (fileNames:string[], fileContents:string[]) =>{
            
            this.ordersNewOrderViewService.createOrderLinesFromFile(fileNames[0], fileContents[0]);
            
            this.closeDialog(0);
        });
    }
}
