/**
 * Contains definitions and data related to WinkHaus Oscilo 1 tipology
 */
export class WinkHausOscilo1 {

    /** List of available heights for the tipology */
    heightSizes = ["515-605", "606-895", "896-1145", "1146-1495", "1496-1655", "1656-1895", "1896-2395"];


    /** List of available widths for the tipology */
    widthSizes = ["365-695", "696-895", "896-1120", "1121-1345", "1346-1545"];


    /** List of values that match each height/width for the right hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    rightValues = ["HM760518", "HM780518", "HM710518", "HM712518", "HM714518", "HM760808", "HM780808", "HM710808", "HM712808", "HM714808", "HM760108", "HM780108", "HM710108", "HM712108", "HM714108", "HM760148", "HM780148", "HM710148", "HM712148", "HM714148", "HM760158", "HM780158", "HM710158", "HM712158", "HM714158", "HM760188", "HM780188", "HM710188", "HM712188", "HM714188", "HM760238", "HM780238", "HM710238", "HM712238", "HM714238"];


    /** List of values that match each height/width for the left hand tipo. This is sorted as a table from left to right, each row after the other, and uses heights as rows and widths as cols */
    leftValues = ["HM760519", "HM780519", "HM710519", "HM712519", "HM714519", "HM760809", "HM780809", "HM710809", "HM712809", "HM714809", "HM760109", "HM780109", "HM710109", "HM712109", "HM714109", "HM760149", "HM780149", "HM710149", "HM712149", "HM714149", "HM760159", "HM780159", "HM710159", "HM712159", "HM714159", "HM760189", "HM780189", "HM710189", "HM712189", "HM714189", "HM760239", "HM780239", "HM710239", "HM712239", "HM714239"];


    /** list of codes for finishes products that must be added to the final product line when right hand is selected */
    rightAdd = ["HE3638XX", "CI4004XX"];


    /** list of codes for finishes products that must be added to the final product line when left hand is selected */
    leftAdd = ["HE3639XX", "CI4004XX"];
}
