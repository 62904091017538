<h2>
    <img id="routesBackButton" src="./assets/icons/arrow-back-black.svg"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="closeRouteDetails()"/>
        
    {{ls.get('EXPANSION', 'app')}}
    
    <img id="editRouteButton" src="./assets/icons/edit.svg"
        matTooltip="{{ls.get('EDIT_ROUTE', 'app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="editRoute()"/>
        
    <img id="dateFilterButton" src="./assets/icons/calendar.svg"
        matTooltip="{{this.ls.get('FILTER_BY_ESTIMATE_DATE', 'app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="selectDateFilterDate()"/>
            
    <img id="deleteRouteButton" src="./assets/icons/delete.svg"
        matTooltip="{{ls.get('DELETE_ROUTE', 'app')}}"
        *ngIf="expansionRoutesViewService.isShowingRouteDetails"
        (click)="deleteRoute()"/>
     
    <img id="addRouteButton" src="./assets/icons/add.svg"
        matTooltip="{{ls.get('CREATE_NEW_ROUTE', 'app')}}"
        *ngIf="!expansionRoutesViewService.isShowingRouteDetails"
        (click)="createNewRoute()"/>
    
</h2>
  

<div class="routesContainer"
    *ngIf="!expansionRoutesViewService.isShowingRouteDetails">

    <tg-button-container class="routeContainer" 
        [releaseOnMouseUp]="false"
        [defaultOpacity]=".7"
        [hoverOpacity]=".8"
        [clickOpacity]="1"
        [clickScale]="1.03"
        *ngFor="let item of expansionRoutesViewService.routesList; let i = index"
        (click)="showRouteDetails(item)">
        
        <img src="./assets/icons/local-shipping.svg" />  
        <h3>{{item.name}}</h3>
        <p>{{item.customers}}</p>
        
    </tg-button-container>
    
</div>

<div class="routeDetailsContainer" [@contentFade]
    *ngIf="expansionRoutesViewService.isShowingRouteDetails">

    <h3>
        {{ls.get('ROUTE_DETAILS', 'app') + ': ' + expansionRoutesViewService.routeBeingCurrentlyEdited.name}}
    </h3>
    
    <p id="clearDateFilterButton"
        *ngIf="expansionRoutesViewService.routeDetailsDateFilter !== ''"
        (click)="clearDateFilter()">
        {{this.ls.get('FILTERED_BY_ESTIMATE_DATE', 'app') + ': ' + expansionRoutesViewService.routeDetailsDateFilter}}
        
    </p>
    
    <div class="headerContainer">
        <h4>{{ls.get('ORDER_NUMBER', 'shopping')}}</h4>
        <h4>{{ls.get('ORDER_DATE', 'shopping')}}</h4>
        <h4>{{ls.get('DELIVERY_DATE', 'app')}}</h4>
        <h4 class="hideOnMobile">{{ls.get('ESTIMATE_DATE', 'app')}}</h4>
    </div>
    
    <div class="tableContainer"
        *ngFor="let customerData of expansionRoutesViewService.routeDetails | keyvalue">
        
        <h5>{{ls.get('CUSTOMER', 'shopping') + ': ' + customerData.key}}</h5>
        
        <tg-button-container *ngFor="let item of customerData.value; let i = index"
            id="ordersListItem_{{i}}"
            [releaseOnMouseUp]="false"
            [defaultOpacity]=".8"
            [hoverOpacity]="1"
            [clickScale]="1.04"
            [class.hasPendingToReceive]="item[4] === true"
            (click)="expansionRoutesViewService.loadOrderData(item[0], customerData.key)">
            
            <p>{{item[0]}}</p>
            <p>{{item[1]}}</p>
            <p>{{item[2]}}</p>
            <p class="hideOnMobile">{{item[3]}}</p>
                    
        </tg-button-container>
        
    </div>
        
</div>